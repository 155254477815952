import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './layout/default';
import Login from './pages/login';
import Register from './pages/register';
import { ErrorPage } from './pages/errorPage';
import Dashboard from './pages/dashboard';
import { PrivateRoutes } from './routes/PrivateRoutes';
import { PublicRoutes } from './routes/PublicRoutes';
import { ResetPassword } from './pages/resetPassword';
import { ChangePassword } from './pages/changePassword';
import { UserList } from './pages/userList';
import { UserDetails } from './pages/userDetail';
import { Invite } from './pages/invite';
import { PendingVerification } from './pages/pendingVerification';
import { Platforms } from './pages/platforms';
import Reports from './pages/reports';
import './App.css';
import { PaymentWidget } from './pages/paymentWidget';
import { ThirdParty } from './pages/thirdParty';

const ApiDocs = lazy(() => import('./pages/apiDocs'));
const AdminDashboard = lazy(() => import('./pages/adminDashboard'));
const AdminPartnerOverview = lazy(() => import('./pages/adminPartnerOverview'));
const AdminPartnerProducts = lazy(() => import('./pages/adminPartnerProducts'));
const AdminPartnerUsers = lazy(() => import('./pages/adminPartnerUsers'));
const AdminProductUsers = lazy(() => import('./pages/adminProductUsers'));
const AdminProductOverview = lazy(() => import('./pages/adminProductOverview'));
const AdminProductTransfers = lazy(() => import('./pages/adminProductTransfers'));
const AdminTransactions = lazy(() => import('./pages/adminTransactions'));
const PlaidLinkTest = lazy(() => import('./pages/plaidLink'));


type Props = {
  isOnline: boolean;
};

export function Router({ isOnline }: Props) {

  return (
    <Routes>
      {isOnline ? (
        <>
          {/** public routes */}
          <Route element={<PublicRoutes />}>
            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login />} />
            <Route path='/password-updated' element={<Login updatedPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/change-password' element={<ChangePassword />} />
            <Route path='/invite-partner-user' element={<Invite />} />
            <Route path='/invite-staff-user' element={<Invite />} />
            <Route path='/nabi/api-docs/*' element={<ApiDocs publicDocsUrl={'nabi'} />} />
          </Route>
          {/** private routes */}
          <Route element={<PrivateRoutes />}>
            <Route path='/' element={<Layout />} >
              <Route path='/' element={<Dashboard />} />
              <Route path='/product/:product_id/platforms' element={<Platforms />} />
              <Route path='/product/:product_id/widget' element={<PaymentWidget />} />
              <Route path='/product/:product_id/3rdparty' element={<ThirdParty />} />
              <Route path='/product/:product_id' element={<Dashboard />} />
              <Route path='/product/:product_id/users' element={<UserList />} />
              <Route path='/product/:product_id/user/:user_id' element={<UserDetails />} />
              <Route path='/product/:product_id/reports' element={<Reports />} />
              <Route path='/product/:product_id/users/:user_id/plaid-link-test' element={<PlaidLinkTest />} />
            </Route>
            {/** Lazy loading */}
            <Route path='/verification-pending' element={<PendingVerification />} />
            <Route path='/staff-admin' element={<AdminDashboard />} />
            <Route path='/staff-admin/:partner_id' element={<AdminPartnerOverview />} />
            <Route path='/staff-admin/:partner_id/products' element={<AdminPartnerProducts />} />
            <Route
              path='/staff-admin/:partner_id/partner-users'
              element={<AdminPartnerUsers />}
            />
            <Route
              path='/staff-admin/:partner_id/:product_id'
              element={<AdminProductOverview />}
            />
            <Route
              path='/staff-admin/:partner_id/:product_id/product-users'
              element={<AdminProductUsers />}
            />
            <Route
              path='/staff-admin/:partner_id/:product_id/transfers'
              element={<AdminProductTransfers />}
            />
            <Route
              path='/staff-admin/:partner_id/:product_id/transactions'
              element={<AdminTransactions />}
            />
            <Route path='/api-docs/*' element={<ApiDocs />} />
          </Route>
          <Route path='*' element={<ErrorPage variant='NOT_FOUND' />} />
        </>
      ): (
        <Route path='*' element={<ErrorPage variant='CONNECTION_LOST' />} />
      )}
    </Routes>
  );
}

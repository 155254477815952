import { useCookies } from "react-cookie";
import { useAxios } from "./useAxios";
import { RewardsDTO, RewardsRequestDTO } from "../../dtos/rewards/rewardsDTO";

export function useRewardsAPI() {
  const [cookies] = useCookies(["lynk.access-token", "lynk.partner-id"]);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies["lynk.access-token"]}` },
  };

  const { getApi, postApi, patchApi, deleteApi } = useAxios();

  async function getRewards(
    productId: string, 
  ): Promise<RewardsDTO> {
    return getApi(`/products/${productId}/reward`, authToken, false, true );
  }

  async function postRewards(
    productId: string, 
    data: RewardsRequestDTO,
  ): Promise<RewardsDTO> {
    return postApi(`/products/${productId}/reward`, data, authToken );
  }

  async function deleteRewards(
    productId: string, 
  ): Promise<RewardsDTO> {
    return deleteApi(`/products/${productId}/reward`, authToken );
  }

  async function updateRewards(
    productId: string, 
    data: RewardsRequestDTO,
  ): Promise<RewardsDTO> {
    return patchApi(`/products/${productId}/reward`, data, authToken );
  }

  async function getTerminalRewardsPoints(
    productId: string,
    terminalUserId: string,
  ): Promise<RewardsDTO> {
    return getApi(
      `/products/${productId}/terminal_users/${terminalUserId}/reward_points`,
      authToken,
      false,
      true,
    );
  }

  async function getTerminalRewardsCashback(
    productId: string,
    terminalUserId: string,
  ): Promise<RewardsDTO> {
    return getApi(
      `/products/${productId}/terminal_users/${terminalUserId}/cashback`,
      authToken,
      false,
      true,
    );
  }

  async function getProductRewardsPoints(
    productId: string,
    productUserId: string,
  ): Promise<RewardsDTO> {
    return getApi(
      `/products/${productId}/terminal_users/${productUserId}/reward_points`,
      authToken,
      false,
      true,
    );
  }

  async function getProductlRewardsCashback(
    productId: string,
    productUserId: string,
  ): Promise<RewardsDTO> {
    return getApi(
      `/products/${productId}/terminal_users/${productUserId}/cashback`,
      authToken,
      false,
      true,
    );
  }

  return {
    getRewards,
    postRewards,
    deleteRewards,
    updateRewards,
    getTerminalRewardsPoints,
    getTerminalRewardsCashback,
    getProductRewardsPoints,
    getProductlRewardsCashback
  };
}
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { KarteraDialogue } from "../../kartera/dialogue";
import { KarteraTextField } from "../../kartera/textField";
import { color } from "../../../consts/ColorConst";
import { KarteraAlert } from "../../kartera/alert";
import { KarteraSwitch } from "../../kartera/switch";
import { KarteraRadioGroup } from "../../kartera/radioGroup";
import { KarteraCurrencyField } from "../../kartera/currencyField";
import { RewardsRequestDTO } from "../../../dtos/rewards/rewardsDTO";
import { formatFloat } from "../../../utils/handleValuesHelper";
import {
  setRewardsModalStep,
  setRewardModalOpen,
} from '../../../reducers/rewardsReducers';
import { AppRootState } from "../../../reducers";
import { hasErrorsInFields } from "../../../utils/helperFunctions";

const useStyles = makeStyles<Theme>(() => ({
  message: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2
  },
  errorMessage: {
    marginTop: -6, 
    fontFamily: "inherit",
    fontSize: 11,
    color: color.RED,
  },
  errorText: {
    color: color.RED_DARK_1,
  },
  content: {
    display: 'flex', 
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 6,
    gap: 14,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  bottomContent: {
    display: 'flex', 
    flexDirection: 'column',
    gap: 10, 
    color: color.GRAY_DARK_01
  },
  sliderContainer: {
    display: 'flex', 
    justifyContent: 'center',
    gap: 12, 
  },
  lineWrapper: {
    display: 'flex', 
    alignItems: 'center',
    gap: 8, 
    marginBottom: 8,
    textTransform: 'none',
  },
  radioLineWrapper: {
    display: 'flex', 
    alignItems: 'center',
    gap: 8, 
    marginTop: -4,
    marginBottom: 10,
    textTransform: 'none',
  },
  alertWrapper: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'pre-line',
    padding: '4px 0px',
    gap: 4,
  },
  alertInfoMessage: {
    "& strong": {
      fontWeight: 400,
    },
  },
  alertWarningMessage: {
    "& strong": {
      fontWeight: 500,
    },
  },
  avoidLossMessage: {
    marginTop: 12,
  },
}));

const reduxConnector = connect((state: AppRootState) => ({
  earnRatio: state.rewardsState.point_system_earn_ratio,
  spendRatio: state.rewardsState.point_system_spend_ratio,
  introOffer: state.rewardsState.point_system_intro_offer,
  introOfferType: state.rewardsState.point_system_intro_offer_type,
  introOfferParam: state.rewardsState.point_system_intro_offer_param,
  introOfferLimit: state.rewardsState.point_system_intro_offer_tnx_limit,
  introOfferMinimunValue: state.rewardsState.point_system_intro_offer_minimun_transaction,
}))

type PointsProps = {
  onDiscard: () => void;
  createRewards: (data: RewardsRequestDTO) => void;
}

type Props = PointsProps & ConnectedProps<typeof reduxConnector>;

function PointsDialog({ 
  onDiscard, 
  createRewards, 
  introOfferLimit,
  introOfferParam,
  introOfferType,
  introOffer,
  introOfferMinimunValue,
  earnRatio,
  spendRatio,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "rewards" });

  const [ introOfferMinimunValueState, setIntroOfferMinimunValueState ] = useState(
    introOfferMinimunValue || parseInt(introOfferLimit || '0') > 0
  );
  const [ introOfferTypeState, setIntroOfferTypeState ] = useState(introOfferType);
  const [ introOfferLimitState, setIntroOfferLimitState ] = useState(introOfferLimit);
  const [ introOfferState, setIntroOfferState ] = useState(introOffer);
  const [ earnRatioState, setEarnRatioState ] = useState(earnRatio || "0");
  const [ spendRatioState, setSpendRatioState ] = useState(spendRatio || "0");
  const [ introOfferMultiplePoints, setIntroOfferMultiplePoints ] = useState(
    introOfferType === 'MULTIPLY_FOR_FIRST_PURCHASE' && introOfferParam ? introOfferParam : "2");
  const [ introOfferExtraPoints, setIntroOfferExtraPoints ] = useState(
    introOfferType === 'EARN_FOR_FIRST_PURCHASE' && introOfferParam ? introOfferParam : "100");
  const [ errors, setErrors ] = useState({
    'redemption': '',
    'collection': '',
    'introdutoryOffer': '',
    'limit': '',
  });

  const collect = +earnRatioState * 100;
  const redeem = (collect / +spendRatioState) || 0;
  const breakage = (redeem * 0.70) || 0;
  const revenue = (143 - breakage) || 0;
  const isLoss = revenue < 0;

  function handleMessageValues(value: number) {
    if (Math.abs(value) === Infinity) {
      return '$0';
    }
    return `$${Math.abs(value).toFixed(2)}`;
  }

  function handleSubmit() {
    const offerParam =
      introOfferTypeState === 'MULTIPLY_FOR_FIRST_PURCHASE'
        ? introOfferMultiplePoints
        : introOfferExtraPoints;
    const offerLimit = introOfferMinimunValueState ? introOfferLimitState : 0;
    const data: RewardsRequestDTO = {
      reward_type: 'POINT_SYSTEM',
      cashback_validity_days: 0,
      discount_percentage: '0.00',
      point_system_spend_ratio: formatFloat(spendRatioState.toString()),
      point_system_earn_ratio: formatFloat(earnRatioState.toString()),
      point_system_intro_offer: introOfferState,
      point_system_intro_offer_type: introOfferTypeState,
      point_system_intro_offer_param: formatFloat(offerParam.toString()),
      point_system_intro_offer_tnx_limit: formatFloat(offerLimit.toString()),
    };

    createRewards(data);

  }

  function handleErrors(points: string, fieldName: string) {
    if (!points) {
      setErrors((state) => ({ ...state, [fieldName]: t('mandatoryField') }));
    } else if (points==='0') {
      setErrors((state) => ({ ...state, [fieldName]: t('zeroError') }));
    }else if (/[A-Za-z]/g.test(points)) {
      setErrors((state) => ({ ...state, [fieldName]: t('numericError') }));
    } else if (/[^0-9]/g.test(points)) {
      setErrors((state) => ({ ...state, [fieldName]: t('floatError') }));
    } else if (Number(points) > 999) {
      setErrors((state) => ({ ...state, [fieldName]: t('maximumError') }));
    } else {
      setErrors((state) => ({ ...state, [fieldName]: '' }));
    }
  }

  function handleCancel() {
    dispatch(setRewardsModalStep('SELECT_TYPE'));
    dispatch(setRewardModalOpen(false));
  }

  function handleIntroOffer() {
    if (introOfferState) {
      setIntroOfferMultiplePoints('2');
      setIntroOfferExtraPoints('100');
      setIntroOfferLimitState('0');
      setIntroOfferMinimunValueState(false);
      setErrors((state) => ({ ...state, 'limit': '', 'introdutoryOffer': ''}));
    }
    setIntroOfferState(!introOfferState);
  }

  function handleMinimunTransaction() {
    if (introOfferMinimunValueState) {
      setErrors((state) => ({ ...state, 'limit': '' }));
    }
    setIntroOfferMinimunValueState(!introOfferMinimunValueState);
  }

  const disableButton =
    parseInt(earnRatioState) === 0 ||
    parseInt(spendRatioState) === 0 ||
    (introOfferMinimunValueState && parseInt(introOfferLimitState) === 0) ||
    hasErrorsInFields(errors, ['redemption', 'collection', 'introdutoryOffer', 'limit']) ||
    (!introOfferTypeState && introOfferState);


  return (
    <KarteraDialogue
      id={'rewards-dialog'}
      title={t('pointsTitle')}
      steps={[
        {
          onCancelClick: () => {
            dispatch(setRewardsModalStep('SELECT_TYPE'));
          },
          content: (
            <div className={classes.content}>
              <p>
                <strong>{t('pointsCollection')}</strong>
              </p>
              <p>{t('pointsCollectionDescription')}</p>
              <div>
                <div
                  className={`${classes.lineWrapper} ${errors['collection'] && classes.errorText}`}
                >
                  {t('everySpent')}
                  <FontAwesomeIcon icon={faArrowRight} />
                  <KarteraTextField
                    placeholder='00'
                    width={56}
                    value={earnRatioState.split('.')[0]}
                    onChange={(e: any) => setEarnRatioState(e.target.value)}
                    onBlur={(e) => handleErrors(e.target.value, 'collection')}
                    error={!!errors['collection']}
                  />
                  {t('point')}
                </div>
                {errors['collection'] && (
                  <p className={classes.errorMessage}>{errors['collection']}</p>
                )}
              </div>
              <p>
                <strong>{t('pointsRedemption')}</strong>
              </p>
              <p>{t('pointsRedemptionDescription')}</p>
              <div>
                <div
                  className={`${classes.lineWrapper} ${errors['redemption'] && classes.errorText}`}
                >
                  <KarteraTextField
                    placeholder='00'
                    width={56}
                    value={spendRatioState.split('.')[0]}
                    type='monetary'
                    onChange={(e: any) => setSpendRatioState(e.target.value)}
                    onBlur={(e) => handleErrors(e.target.value, 'redemption')}
                    error={!!errors['redemption']}
                  />
                  {t('point')}
                  <FontAwesomeIcon icon={faArrowRight} />
                  <strong>$1</strong>
                  {t('reduction')}
                </div>
                {errors['redemption'] && (
                  <p className={classes.errorMessage}>{errors['redemption']}</p>
                )}
              </div>
              {errors['redemption'] || errors['collection'] ? (
                <KarteraAlert severity='error' supressCloseButton text={t('pointsErrorMessage')} />
              ) : (
                <KarteraAlert
                  severity={isLoss ? 'warning' : 'info'}
                  supressCloseButton
                  text={
                    <div className={classes.alertWrapper}>
                      <p>
                        <strong>{t('exampleAlertTitle')}</strong>
                      </p>
                      <p 
                        className={
                          isLoss ? classes.alertWarningMessage : classes.alertInfoMessage
                        }
                      >
                        <Trans>
                          {t('exampleAlertBody', {
                            collect: collect,
                            redeem: handleMessageValues(redeem),
                            breakage: handleMessageValues(breakage),
                            revenue: handleMessageValues(revenue),
                            revenue_loss: isLoss ? 'loss' : 'revenue',
                          })}
                        </Trans>
                      </p>
                      {isLoss && (
                        <p className={classes.avoidLossMessage}>
                          <strong>{t('messageAvoidLoss')}</strong>
                        </p>
                      )}
                    </div>
                  }
                />
              )}
            </div>
          ),
        },
        {
          actionButtonText: t('finish'),
          onActionClick: handleSubmit,
          content: (
            <div className={classes.content}>
              <p>
                <strong>{t('introductoryOffer')}</strong>
              </p>
              <p>{t('introductoryOfferDescription')}</p>
              <KarteraSwitch
                label={introOfferState ? t('active') : t('inactive')}
                checked={introOfferState}
                onChange={handleIntroOffer}
                sx={{ marginLeft: -4 }}
              />
              <p style={{ color: color.GREY_DARK_1 }}>{t('introductoryOfferType')}</p>
              <div>
                <KarteraRadioGroup
                  disabled={!introOfferState}
                  sx={{ width: 'max-content' }}
                  items={[
                    {
                      text: (
                        <div
                          className={`${classes.radioLineWrapper} ${
                            errors['introdutoryOffer'] && classes.errorText
                          }`}
                        >
                          <p>{t('earnMultiple')}</p>
                          <KarteraTextField
                            placeholder='00'
                            width={72}
                            value={introOfferMultiplePoints.split('.')[0]}
                            onChange={(e: any) => setIntroOfferMultiplePoints(e.target.value)}
                            onBlur={(e) => handleErrors(e.target.value, 'introdutoryOffer')}
                            error={!!errors['introdutoryOffer']}
                            disabled={
                              !introOfferState ||
                              introOfferTypeState !== 'MULTIPLY_FOR_FIRST_PURCHASE'
                            }
                          />
                          {t('point')}
                        </div>
                      ),
                      value: 'MULTIPLY_FOR_FIRST_PURCHASE',
                    },
                    {
                      text: (
                        <div
                          className={`${classes.radioLineWrapper} ${
                            errors['introdutoryOffer'] && classes.errorText
                          }`}
                        >
                          <p>{t('earnExtra')}</p>
                          <KarteraTextField
                            placeholder='00'
                            width={72}
                            value={introOfferExtraPoints.split('.')[0]}
                            onChange={(e: any) => setIntroOfferExtraPoints(e.target.value)}
                            onBlur={(e) => handleErrors(e.target.value, 'introdutoryOffer')}
                            error={!!errors['introdutoryOffer']}
                            disabled={
                              !introOfferState || introOfferTypeState !== 'EARN_FOR_FIRST_PURCHASE'
                            }
                          />
                          {t('point')}
                        </div>
                      ),
                      value: 'EARN_FOR_FIRST_PURCHASE',
                    },
                  ]}
                  onChange={(e: any) => {
                    if (introOfferTypeState === 'EARN_FOR_FIRST_PURCHASE') {
                      handleErrors(introOfferMultiplePoints, 'introdutoryOffer');
                    } else {
                      handleErrors(introOfferExtraPoints, 'introdutoryOffer');
                    }
                    setIntroOfferTypeState(e.target.value);
                  }}
                  value={introOfferTypeState}
                  errorMessage={errors['introdutoryOffer']}
                />
                <div>
                  <div className={`${classes.lineWrapper} ${errors['limit'] && classes.errorText}`}>
                    <KarteraSwitch
                      label={t('minimunTransaction')}
                      disabled={!introOfferState}
                      checked={introOfferMinimunValueState}
                      onClick={handleMinimunTransaction}
                      sx={{ height: 28, marginLeft: -4 }}
                    />
                    {introOfferMinimunValueState && (
                      <KarteraCurrencyField
                        placeholder='00'
                        width={76}
                        value={introOfferLimitState}
                        onChange={(e: any) => setIntroOfferLimitState(e.target.value)}
                        onBlur={(e) => handleErrors(e.target.value, 'limit')}
                        error={!!errors['limit']}
                        disabled={!introOfferState}
                      />
                    )}
                  </div>
                  {errors['limit'] && <p className={classes.errorMessage}>{errors['limit']}</p>}
                </div>
              </div>
            </div>
          ),
        },
      ]}
      size='medium'
      width='611px'
      autoHeight
      showCloseButton={false}
      cancelButtonText={t('back')}
      actionButtonText={t('next')}
      activeButtonType='primary'
      othersButtonText={t('discard')}
      onOthersClick={onDiscard}
      onCancelClick={handleCancel}
      activeButtonDisabled={disableButton}
    />
  );
}

export default reduxConnector(PointsDialog);
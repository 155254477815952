import { useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Divider, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { KarteraDialogue } from "../../kartera/dialogue";
import { KarteraSlider } from "../../kartera/slider";
import { KarteraSwitch } from "../../kartera/switch";
import { KarteraTextField } from "../../kartera/textField";
import { color } from "../../../consts/ColorConst";
import { RewardsRequestDTO, RewardsType } from "../../../dtos/rewards/rewardsDTO";
import { formatFloat } from "../../../utils/handleValuesHelper";
import {
  setRewardsModalStep,
  setRewardModalOpen,
  setCashbackBreakage,
  setRewardType,
} from '../../../reducers/rewardsReducers';
import { AppRootState } from "../../../reducers";

const useStyles = makeStyles<Theme>(() => ({
  message: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 6,
    gap: 14,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  bottomContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    color: color.GRAY_DARK_01,
  },
  sliderContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: 12,
  },
  monthsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  errorText: {
    fontSize: 11,
    color: color.RED,
  },
}));

const reduxConnector = connect((state: AppRootState) => ({
  validityDays: state.rewardsState.cashback_validity_days,
  discountPercentage: state.rewardsState.discount_percentage,
  cashbackBreakage: state.rewardsState.cashback_breakage,
}));

type DiscountOrCashbackProps = {
  onDiscard: () => void;
  rewardType: RewardsType;
  createRewards: (data: RewardsRequestDTO, type: 'CREATE' | 'UPDATE') => void;
}

type Props = DiscountOrCashbackProps & ConnectedProps<typeof reduxConnector>;

function DiscountOrCashbackDialog({ 
  onDiscard, 
  rewardType, 
  createRewards,
  validityDays,
  discountPercentage,
  cashbackBreakage,
}: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "rewards" });
  
  const [error, setError] = useState('');

  const [validityDaysState, setValidityDaysState] = useState<number>(validityDays);
  const [discountPercentageState, setDiscountPercentageState] = useState<number>(discountPercentage);
  const [cashbackBreakageState, setCashbackBreakageState] = useState<boolean>(cashbackBreakage);

  function handleSubmit() {
    const data: RewardsRequestDTO = {
      reward_type: rewardType as RewardsType,
      cashback_validity_days: rewardType === 'CASHBACK' ? validityDaysState : 0,
      discount_percentage: formatFloat(discountPercentageState.toString()),
      point_system_earn_ratio: '0.00',
      point_system_spend_ratio: '0.00',
      point_system_intro_offer: false,
    };
    dispatch(setRewardType(rewardType as RewardsType));
    dispatch(setCashbackBreakage(cashbackBreakageState));
    createRewards(data, 'CREATE');
    console.log(data,"I am here")
  }

  function handleCancel() {
    dispatch(setRewardsModalStep('SELECT_TYPE'));
    dispatch(setRewardModalOpen(false));
  }

  function handleSliderChange(e: any) {
    setDiscountPercentageState(e.target.value);
  }

  function handleValidityDays(e: any) {
    setValidityDaysState(e.target.value);
  }

  function handleCashBackBreakage() {
    setCashbackBreakageState(!cashbackBreakageState);
    setValidityDaysState(0);
    setError('');
  }

  function handleAmountBlur() {
    if (Number(validityDaysState) < 0) {
      setError(t('errorNegativeAmount'));
    } else if (!Number(validityDaysState)) {
      setError(t('errorValidAmount'));
    } else if (Number(validityDaysState) > 2147483647) {
      setError(t('maximumDaysError'));
    } else {
      setError('');
    }
  }

  const disableNextButton = rewardType === 'CASHBACK' && 
          (cashbackBreakageState && validityDaysState === 0) || 
          error !== '';

  return (
    <KarteraDialogue
      id={'rewards-dialog'}
      title={
        rewardType === 'INSTANT_DISCOUNT' ? t('instantDiscountTitle') : t('cashBackCreditTitle')
      }
      size='medium'
      width='611px'
      autoHeight
      onCancelClick={handleCancel}
      actionButtonText={t('finish')}
      cancelButtonText={t('back')}
      activeButtonType='primary'
      onActionClick={handleSubmit}
      showCloseButton={false}
      othersButtonText={t('discard')}
      onOthersClick={onDiscard}
      activeButtonDisabled={disableNextButton}
      steps={[
        {
          onCancelClick: () => {
            dispatch(setRewardsModalStep('SELECT_TYPE'));
          },
          onActionClick: handleSubmit,
          content: (
            <div className={classes.content}>
              <p>
                <strong>
                  {rewardType === 'INSTANT_DISCOUNT'
                    ? t('instantDiscountSubtitle')
                    : t('cashBackCreditSubtitle')}
                </strong>
              </p>
              <p>
                {rewardType === 'INSTANT_DISCOUNT'
                  ? t('instantDiscountText')
                  : t('cashBackCreditText')}
              </p>
              <div className={classes.sliderContainer}>
                <KarteraSlider
                  style={{ width: 490 }}
                  marks={[
                    { label: '0%', value: 0 },
                    { label: '0.5', value: 0.5 },
                    { label: '1%', value: 1 },
                    { label: '1.5', value: 1.5 },
                    { label: '2%', value: 2 },
                    { label: '2.5', value: 2.5 },
                    { label: '3%', value: 3 },
                    { label: '3.5', value: 3.5 },
                    { label: '4%', value: 4 },
                    { label: '4.5', value: 4.5 },
                    { label: '5%', value: 5 },
                  ]}
                  step={0.25}
                  min={0}
                  max={5}
                  defaultValue={discountPercentageState}
                  onChange={handleSliderChange}
                />
              </div>
              <Divider variant='fullWidth' />
              {rewardType === 'CASHBACK' && (
                <div className={classes.bottomContent}>
                  <p>
                    <strong>{t('cashBackBreakage')}</strong>
                  </p>
                  <p>{t('cashBackBreakageText')}</p>
                  <KarteraSwitch
                    label={cashbackBreakageState ? t('active') : t('inactive')}
                    checked={cashbackBreakageState}
                    onChange={handleCashBackBreakage}
                  />
                  <p>{t('expirationDuration')}</p>
                  <div className={classes.monthsWrapper}>
                    <KarteraTextField
                      placeholder='00'
                      width={56}
                      value={validityDaysState}
                      onChange={handleValidityDays}
                      error={error !== ''}
                      onBlur={handleAmountBlur}
                      disabled={!cashbackBreakageState}
                    />
                    <div>{t('days')}</div>
                  </div>
                  {error !== '' && <div className={classes.errorText}>{error}</div>}
                </div>
              )}
            </div>
          ),
        },
      ]}
    ></KarteraDialogue>
  );
}

export default reduxConnector(DiscountOrCashbackDialog);
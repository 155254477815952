import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Trans, useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { color } from "../../consts/ColorConst";
import walletImage from "../../assets/wallet.png";
import { KarteraButton } from "../../components/kartera/button";
import CustomizeWidgetDialog  from "../../components/paymentWidget/customizeWidgetDialog/CustomizeWidgetDialog";

const useStyles = makeStyles((theme: any) => ({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: color.GRAY_01,
    padding: '35px 40px',
  },
  contentContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  platformContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 32,
    marginBottom: 32,
  },
  title: {
    fontSize: 26,
    fontWeight: 700,
    padding: '0 0 31px',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  message: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2
  },
  dialogContainer: {
    width: 760,
    padding: 16,
    background: color.WHITE_01,
    borderRadius: 6,
    boxShadow: theme.shadow.elevation4Grey,
    marginBottom: 40,
  },
  messageContainer: {
    display: 'flex', 
    padding: '8px 0 0',
    alignItems: 'center',
  },
  innerMessageContainer: {
    display: 'flex', 
    flexDirection: 'column',
    padding: '8px 0 0 16px',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
    gap: 10,
  },
  image: {
    height: 160,
    margin: '20px',
  },
  button: {
    width: 'fit-content',
    padding: 0,
  }
}));

export function PaymentWidget() {
  const classes = useStyles();
  const { t } = useTranslation('translation', { keyPrefix: 'paymentWidget' });

  const [showCustomizeWidgetDialog, setShowCustomizeWidgetDialog] = useState(true);

  document.title = 'Kartera - Payment widget';

  return (
    <Box className={classes.root}>
      <Box className={classes.contentContainer}>
        <Box className={classes.titleContainer}>
          <Typography className={classes.title}>
            {t('pageTitle')}
          </Typography>
        </Box>
        {showCustomizeWidgetDialog ? (
          <CustomizeWidgetDialog 
            onClose={() => setShowCustomizeWidgetDialog(true)} 
          /> 
        ) : (
          <div className={classes.dialogContainer}>
            <Typography  className={classes.subtitle}>
              {t('panelTitle')}
            </Typography>
            <div className={classes.messageContainer}>
              <img 
                className={classes.image} 
                src={walletImage} 
                alt="Plugins image"
              />
              <div className={classes.innerMessageContainer}>
                <Trans><p>{t('panelP1')}</p></Trans>
                <Trans><p>{t('panelP2')}</p></Trans>
                <p>{t('panelP3')}</p>
                <p>{t('panelP4')}</p>
                <KarteraButton
                  className={classes.button}
                  text={t('learnMoreButton')}
                  variant="tertiary"
                  onClick={() => window.open('https://kartera.com/', '_blank')}
                />
                <KarteraButton
                  text={t('panelButton')}
                  variant="primary"
                  onClick={() => setShowCustomizeWidgetDialog(true)}
                />
              </div>
            </div>           
          </div>
        )}
      </Box>
    </Box>
  );
}

import { useCookies } from 'react-cookie';
import { useAxios } from './useAxios';
import { WidgetDTO } from '../../dtos/widget/widgetDTO';

export function useWidgetAPI() {
  const [cookies] = useCookies(['lynk.access-token', 'lynk.partner-id']);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies['lynk.access-token']}` },
  };

  const { getApi, postApi, patchApi, deleteApi } = useAxios();

  async function getWidget(
    terminalId: string,
  ): Promise<WidgetDTO> {
    return getApi(
      `/terminals/${terminalId}/widget_settings`,
      authToken,
      true,
      true,
      true,
      2,
    );
  }

  async function postWidget(terminalId: string, data: any): Promise<WidgetDTO> {

    return postApi(
      `/terminals/${terminalId}/widget_settings`,
      data,
      authToken,
      () => '',
      true,
      false,
      2,
    );
  }

  async function updateWidget(terminalId: string, data: any): Promise<WidgetDTO> {
    return patchApi(`/terminals/${terminalId}/widget_settings`, data, authToken, () => '', true, 2);
  }

  return {
    getWidget,
    postWidget,
    updateWidget,
  };
}
